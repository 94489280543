import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Form, Input, Textarea } from "./style";
import Button from "../../components/shared/button";
import { useForm } from "react-hook-form";
import Modal from "../pop/index";
import axios from "axios";

const MessageFrom = () => {
    const [open, setOpen] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        const { first_name, email, phonenumber, company, message } = data;

        const webhookUrl =
            "https://hooks.slack.com/services/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";

        const postData = {
            text: `Name: ${first_name}\n Email: ${email}\n Phone: ${phonenumber}\n Company: ${company}\n Message: ${message}`,
        };

        let res = await axios.post(webhookUrl, JSON.stringify(postData), {
            withCredentials: false,
            transformRequest: [
                (postData, headers) => {
                    delete headers.post["Content-Type"];
                    return postData;
                },
            ],
        });

        if (res.status === 200) {
            reset();
            setOpen(true);
        } else {
            console.log("There was an error.  Please try again later.");
        }
    };

    return (
        <React.Fragment>
            <Modal open={open} setOpen={setOpen} />
            <Form className="row gx-4" onSubmit={handleSubmit(onSubmit)}>
                <Col sm={6}>
                    <Input
                        className="form-control"
                        placeholder="Enter Your Name"
                        type="text"
                        name="first_name"
                        {...register("first_name")}
                    />
                </Col>
                <Col sm={6}>
                    <Input
                        className="form-control"
                        placeholder="Enter Your Contact No."
                        type="number"
                        name="phone"
                        onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 12))
                        }
                        {...register("phonenumber", {
                            required: true,
                            minLength: 8,
                            maxLength: 12,
                        })}
                    />
                    {errors.phonenumber && (
                        <span className="text-red">This field is required</span>
                    )}
                </Col>
                <Col sm={6}>
                    <Input
                        className="form-control"
                        placeholder="Enter Your Email"
                        type="email"
                        name="email"
                        {...register("email", {
                            required: true,
                            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        })}
                    />
                    {errors.email && (
                        <span className="text-red">This field is required</span>
                    )}
                </Col>

                <Col sm={6}>
                    <Input
                        className="form-control"
                        placeholder="Company Name"
                        type="text"
                        name="company"
                        {...register("company")}
                    />
                </Col>

                <Col sm={12}>
                    <Textarea
                        placeholder="Message"
                        className="form-control textarea-control"
                        id="textarea"
                        cols="30"
                        rows="10"
                        name="message"
                        {...register("message")}
                    />
                </Col>
                <Col sm={12}>
                    <Button
                        type="submit"
                        size="large"
                        shape="rounded5"
                        className="btn-absolute"
                    >
                        Let’s Connect
                        {/* <i className="icofont-rounded-double-right"></i> */}
                    </Button>
                </Col>
            </Form>
        </React.Fragment>
    );
};

export default MessageFrom;
