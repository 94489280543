import React from "react";
import Button from "../../components/shared/button/index"
const Model = ({ open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {open && (
                <>
                    <div className="modelBox">
                        <div className="model2 mx-auto">
                            <div className="modelBox-child ">
                                <h3>
                                    <span>Thank</span>
                                    You!
                                </h3>

                                <div className="modelBox-sub-div">
                                    <p
                                        id="swal2-content"
                                    >
                                        Your message was sent successfully. We
                                        will get back to you within a few
                                        minutes.
                                    </p>
                                </div>
                                <Button
                                    className="btn"
                                    type="button"
                                    path="/"
                                    onClick={handleClose}
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="modelBox-bottom-div"></div>
                </>
            )}
        </>
    );
};

export default Model;
