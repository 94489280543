import PropTypes from "prop-types";
import Layout from "@layout";
import SEO from "@components/seo";
import { graphql } from "gatsby";
import PageBreadcrumb from "../components/pagebreadcrumb";
import ContactUsArea from "../container/contact-us";
import { normalizedData } from "@utils/functions";
import BreadcrumbBg from "../data/images/bread/Contactus.png";

const ContactUsPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page.content || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO
                title="Contact"
                pathname="/contactus"
                description="Woorg : The fastest growing performance marketing and advertising agency with clients around 5 continents in multiple industries. Connect now to boost your business."
            />
            <PageBreadcrumb
                backgroundImage={BreadcrumbBg}
                // pageContext={pageContext}
                // location={location}
                title="Contact Us"
            />
            <ContactUsArea data={content["contact-us-section"]} />
        </Layout>
    );
};
ContactUsPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query ContactUsPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    socials {
                        color
                        link
                        icon
                    }
                    ...Footer
                }
            }
        }
        page(title: { eq: "ContactUsPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
                section_title {
                    ...SectionTitle
                }
            }
        }
    }
`;

export default ContactUsPage;
