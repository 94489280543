import PropTypes from "prop-types";
import { ContactMediaList } from "./style";

const GetInTouchArea = ({ icon, title, content, link }) => {
    return (
        <ContactMediaList>
            <a href={link} className="number">
                <div className="icon">
                    <span className={icon}></span>
                </div>
            </a>
            <div className="content">
                <span className="text">{title}:</span>
                <div className="contactus">
                    <a href={link} className="number">
                        {content}
                    </a>
                </div>
            </div>
        </ContactMediaList>
    );
};
GetInTouchArea.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
};
export default GetInTouchArea;
